import type { FC } from "react"
import ReactConfetti from "react-confetti"

export type ModalAlertSeverity = "success" | "failed"

export interface ModalAlertProps {
   options:{
    visible: boolean
    severity?: ModalAlertSeverity
    message?: string
    actionText?: string
    actionClick: () => void
   }
}
const ModalAlert: FC<ModalAlertProps> = (props) => {

    const getSeverityTitle = (severity: ModalAlertSeverity) => {
        switch (severity) {
            case "success":
                return "Success"
            case "failed":
                return "Failed"
        }
    }

    const getSeverityIcon = (severity: ModalAlertSeverity) => {
        switch (severity) {
            case "success":
                return require("../../assets/images/interaction/check-mark.png")
            case "failed":
                return require("../../assets/images/interaction/multiply.png")
        }
    }
    return (
        <>
            {
                props.options.visible && <div className="z-50 fixed top-0 left-0 w-full h-full backdrop-blur-md bg-white/30 flex items-center justify-center">
                    {
                        props.options.severity === "success" && 
                        <ReactConfetti
                        width={window.innerWidth}
                        height={window.innerHeight}
                      />
                    }
                    <div className="bg-gray-50 dark:bg-gray-700 px-10 py-10 rounded-lg flex flex-col items-center justify-center gap-5">
                        <img src={getSeverityIcon(props.options?.severity || "failed")} className="h-20 w-auto m-auto" loading="lazy" alt="client logo" width="" height="" />
                        <span className="text-gray-900 dark:text-gray-300 text-2xl font-bold">{getSeverityTitle(props.options?.severity || "failed")}</span>
                        <span className="text-gray-900 dark:text-gray-300 text-sm font-thin text-center">{props.options.message || ""}</span>

                        <button
                            onClick={props.options.actionClick}
                            className="relative flex h-11 w-full items-center justify-center px-6 before:absolute before:inset-0 before:rounded-full before:bg-primary before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max"
                        >
                            <span className="relative text-base font-semibold text-white">{props.options.actionText ? props.options.actionText : "Done"}</span>
                        </button>
                    </div>

                </div>
            }
        </>

    )
}

export default ModalAlert